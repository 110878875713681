.appDownloadSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 12px;
  margin: 0 1.5rem 80px 1.5rem;
  height: 440px;
  .textContainer {
    padding: 120px 72px;
    h1 {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px;
    }
    p {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 32px;
    }
    .storeButtons {
      img:first-of-type {
        margin-right: 13px;
      }
    }
  }
  .imgAndQR {
    position: relative;
    .background {
      background-image: url("../assets/app_download_main.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0 12px 12px 0;
      width: 640px;
      height: 440px;
    }
    .qr {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 8px;
    }
  }
}
