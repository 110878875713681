.gallerySection {
  margin-bottom: 120px;
  padding-top: 20px;
  padding: 0 1.5rem;
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-size: 32px;
    line-height: 36px;
    line-height: 24px;
    color: #1c1c1e;
  }
  .galleryWrapper {
    overflow-x: scroll;
    margin-top: 40px;
    .galleryContainer {
      padding: 0;
      height: 680px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      gap: 17px;
      .galleryImage {
        max-width: 273px;
        max-height: 387px;
        flex: 1 1 auto;
        object-fit: cover;
      }
    }
  }
  // gallery fix
  .styles-module_close__2I1sI {
    width: 40px;
    height: 40px;
    right: 20px;
    top: 24px;
    z-index: 4;
    line-height: 30px;
  }
  .styles-module_navigation__1pqAE,
  .styles-module_close__2I1sI {
    opacity: 0.5 !important;
  }
}
