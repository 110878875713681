.settingsSection {
  height: 100vh;
  background-color: #f2f2f7;
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    padding: 16px 24px 40px;
  }
  .titleContainer {
    margin: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    h2 {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
    }
    p {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #636366;
    }
  }
  .languagesContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 130px;
    margin-top: 180px;
    .languageCard {
      height: 180px;
      width: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      .circle {
        display: none;
      }
      &.active {
        border: 1px solid #b31400;
        background-color: white;
        border-radius: 12px;
        position: relative;
        .circle {
          position: absolute;
          display: block;
          top: -10px;
          right: -10px;
          width: 20px;
          height: 20px;
        }
      }
      img {
        height: 64px;
        width: 64px;
        border-radius: 50%;
      }
    }
  }
}
