.screenSaver {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.scVisible {
    display: block;
  }
  &.scHidden {
    display: none;
  }
}
