@import "./font.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// custom scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #b31400;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(73, 69, 69);
}

// removes the onclick highlight-color
input,
textarea,
button,
select,
span,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
  border-radius: 12px;
  bottom: 0;
}
.shadow-full {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.48);
  bottom: 0;
}

.center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.noScrollbar {
  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }
}

.closeBtn {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 24px;
  top: 24px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  border: none;
  outline: none;
}

$font-size: 18px;
$line-height: 1.8;
$lines-to-show: 4;

.description {
  padding: 0 1.5rem;
  // cut the half of the text
  display: block;
  display: -webkit-box;
  max-height: $font-size * $line-height * $lines-to-show;
  margin: 0 auto;
  font-size: $font-size;
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 500;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: visibility 0s, opacity 0.5s linear;
  &.showAllText {
    display: block;
    height: auto;
    max-height: fit-content;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: inherit;
    overflow: unset;
    text-overflow: unset;
  }
}
.readMore {
  display: block;
  cursor: pointer;
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #007aff;
  margin-top: 15px;
  padding: 0 1.5rem;
}

// navBar
.navBar {
  position: fixed;
  bottom: 0;
  height: 56px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px -10px 27px -10px rgb(0 0 0 / 40%);
  z-index: 2;
  .btnGroup {
    display: flex;
    height: 100%;
    gap: 300px;
    justify-content: center;
    .navBtn {
      background-color: transparent;
      cursor: pointer;
      border: none;
      outline: none;
      padding: 0;
      img {
        width: 20px;
        height: 20px;
      }
      .activeIcon {
        display: none;
      }
      &.active {
        .icon {
          display: none;
        }
        .activeIcon {
          display: block;
        }
      }
    }
  }
}

// error
.errorSection {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  gap: 20px;
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 38px;
  }
  img {
    height: 450px;
    width: fit-content;
  }
  .btn {
    background: #b31400;
    color: white;
    padding: 15px;
    font-size: 24px;
    border-radius: 8px;
  }
}

//reactModal customise
.ReactModalPortal {
  z-index: 90;
  position: absolute;
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgb(0 0 0 / 75%) !important;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    background: #f2f2f7 !important;
  }
}
