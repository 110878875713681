.artsSection {
  margin: 48px 0;
  padding: 0 1.5rem;
  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 24px;
    h1 {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 36px;
      color: #1c1c1e;
    }
    span {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #b31400;
    }
  }

  .artsContainer {
    display: flex;
    gap: 36px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 35px 0;
    .artCard {
      width: 189px;
      height: 304px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      img {
        border-radius: 12px;
        width: 189px;
        height: 256px;
        object-fit: cover;
      }
      .textContainer {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        h2 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
        }
        h3 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
.newArtsSection {
  margin: 48px 0;
  padding: 1.5rem;
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: #1c1c1e;
    margin-bottom: 24px;
  }

  .artsContainer {
    display: flex;
    gap: 36px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 35px 0;
    .artCard {
      width: 189px;
      height: 304px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      img {
        border-radius: 12px;
        width: 189px;
        height: 256px;
      }
      .textContainer {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        h2 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
        }
        h3 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
.allArtsSection {
  margin: 48px 0;
  padding: 1.5rem;
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: #1c1c1e;
    margin-bottom: 24px;
  }

  .artsContainer {
    display: flex;
    gap: 55px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .artCard {
      width: 189px;
      height: 304px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      .imageContainer {
        width: 190px;
        height: 260px;
        .artImage {
          border-radius: 12px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .textContainer {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        h2 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
        }
        h3 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.artDetailsSection {
  background-color: #f2f2f7;
  .imageContainer {
    height: 550px;
    position: relative;
    padding: 24px;
    margin-bottom: 24px;
    .artImg {
      height: 100%;
      margin-right: auto;
      margin-left: auto;
      display: block;
    }
    .fullScreenBtn {
      position: absolute;
      right: 24px;
      bottom: 0;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      cursor: pointer;
      border: none;
      outline: none;
    }
  }
  .detailsWrapper {
    border-radius: 24px 24px 0 0;
    background-color: white;

    .detailsContainer {
      padding: 1.5rem;
      h1 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      .size {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #636366;
      }
      .description {
        padding: 0;
        margin-top: 48px;
      }
      .readMore {
        padding: 0;
      }
    }
    .artistContainer {
      width: 250px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
      h2 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin-top: 8px;
      }
      h3 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #636366;
      }
      img {
        border-radius: 12px;
        height: 120px;
        width: 120px;
        object-fit: cover;
      }
    }
  }

  // imgFullScreenView fix
  .styles-module_close__2I1sI {
    width: 40px;
    height: 40px;
    right: 20px;
    top: 24px;
    z-index: 4;
    line-height: 30px;
    opacity: 0.5 !important;
  }
}
