.artistsSection {
  margin: 30px 0;
  padding: 1.5rem;
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: #1c1c1e;
    margin-bottom: 24px;
  }

  .artistsContainer {
    display: flex;
    gap: 40px;
    height: 210px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 15px 0;
    .artistCard {
      width: 220px;
      height: 174px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 35px;
      img {
        border-radius: 12px;
        height: 120px;
        width: 120px;
        object-fit: cover;
      }
      .textContainer {
        width: max-content;
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        h2 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
        h3 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #636366;
          text-align: center;
        }
      }
    }
  }
}

.artistSection {
  margin-bottom: 50px;
  .heroImageContainer {
    height: 310px;
    overflow: hidden;
    .mainImg {
      max-height: 100%;
      min-width: 100%;
      object-fit: cover;
      vertical-align: bottom;
    }
  }
  .profilImageContainer {
    margin-top: -65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    h1 {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
    }
    span {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
    .profileImage {
      border-radius: 12px;
      height: 120px;
      width: 120px;
      border: 4px solid white;
      object-fit: cover;
    }
  }
}
