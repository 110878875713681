.exhibitionsSection {
  margin-bottom: 50px;
  padding: 1.5rem;
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: #1c1c1e;
    margin-bottom: 24px;
  }

  .exhibitionContainer {
    display: flex;
    gap: 36px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 15px 0;
    .exhibitionCard {
      width: 387px;
      height: 360px;
      position: relative;
      cursor: pointer;
      img {
        border-radius: 12px;
        width: 387px;
        height: 360px;
        object-fit: cover;
      }
      .textContainer {
        position: absolute;
        bottom: 16px;
        margin: 0 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        z-index: 1;
        .brandImg {
          height: 48px;
          width: 48px;
          padding: 8px;
          background: #f2f2f7;
          border-radius: 50%;
        }
        h2 {
          color: white;
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
        p {
          display: flex;
          align-items: center;
          color: white;
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          font-size: 14px;
          .icon {
            margin-right: 6px;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }
}

.exhibitionDetailsSection {
  background-color: #f2f2f7;
  .imageContainer {
    height: 550px;
    position: relative;
    .artImg {
      max-height: 100%;
      min-width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .titleContainer {
      position: absolute;
      display: flex;
      top: 225px;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      gap: 10px;
      h1 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 42px;
        color: white;
      }
      h2 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: white;
      }
    }
  }
  .detailsWrapper {
    border-radius: 24px 24px 0 0;
    background-color: white;
    position: absolute;
    margin-top: -24px;
    width: 100vw;
    .detailsContainer {
      padding: 1.5rem 0;
      h1 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 24px;
        margin-bottom: 15px;
        &.place {
          padding: 0 1.5rem;
        }
      }
      .address {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #636366;
        display: flex;
        align-items: center;
        padding: 0 1.5rem;
        img {
          height: 20px;
          margin-right: 10px;
        }
      }
      .description {
        margin-top: 48px;
      }
    }
  }

  // imgFullScreenView fix
  .styles-module_close__2I1sI {
    width: 40px;
    height: 40px;
    right: 20px;
    top: 24px;
    z-index: 4;
    line-height: 30px;
    opacity: 0.5 !important;
  }
}

.exhibitionPlaceSection {
  padding: 0 1.5rem;
  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h1 {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 36px;
      color: #1c1c1e;
    }
    span {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #b31400;
    }
  }

  .exhibitionsContainer {
    display: flex;
    gap: 20px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0 0 35px 0;
    margin-bottom: 40px;
    .exhibitionCard {
      width: 300px;
      height: 304px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      img {
        border-radius: 12px;
        width: 300px;
        min-height: 260px;
        height: 260px;
        object-fit: cover;
      }
      .textContainer {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        h2 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
        }
        h3 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #636366;
        }
      }
    }
  }
}
