.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin: 32px 0 36px;
  // padding: 0 1.5rem;
  span {
    height: 24px;
    background: #f2f2f7;
    border-radius: 32px;
    padding: 10px 20px;
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
  }
}
