.mapModalHeader {
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 50px;
  }
}
.mapModalContent {
  position: relative;
  .mapItem {
    position: absolute;
    border-radius: 50%;
    .internal {
      background-color: #b31400;
      border-radius: 50%;
      height: 8px;
      width: 8px;
    }
    &.active {
      border: 6px solid rgb(179 20 0 / 30%);
      .internal {
        height: 12px;
        width: 12px;
      }
    }
  }
}

.artifactList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  margin-top: 40px;
  width: 1232px;
  overflow-y: hidden;
  overflow-x: auto;
  .artItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    height: 72px;
    min-width: 405px;
    padding: 8px;
    border: 1px solid #d1d1d6;
    border-radius: 12px;
    &.active {
      height: 88px;
      img {
        height: 72px;
        width: 72px;
      }
    }
    img {
      border-radius: 8px;
      height: 56px;
      width: 56px;
      object-fit: cover;
    }
    .textContainer {
      h1 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      h2 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #636366;
      }
    }
    p {
      margin-left: auto;
      align-self: flex-end;
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #b31400;
    }
  }
}
