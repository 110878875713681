.placesSection {
  margin-bottom: 48px;
  padding: 1.5rem;
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-size: 32px;
    line-height: 36px;
    line-height: 24px;
    color: #1c1c1e;
    margin-bottom: 24px;
  }

  .placesContainer {
    display: flex;
    gap: 40px;
    padding: 15px 0;
    .placeItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 96px;
      height: 88px;
      cursor: pointer;
      img {
        background: #f2f2f7;
        height: 32px;
        border-radius: 50%;
        padding: 16px;
      }
      p {
        color: #636366;
      }
    }
  }
}

// keptar homepage
.mainSection {
  margin-bottom: 50px;
  .heroImageContainer {
    margin-bottom: 80px;
    height: 270px;
    overflow: hidden;
    .mainImg {
      max-height: 100%;
      min-width: 100%;
      object-fit: cover;
      vertical-align: bottom;
    }
    .logo {
      position: absolute;
      top: 210px;
      left: 45%;
      background: #f2f2f7;
      height: 72px;
      border-radius: 50%;
      padding: 24px;
    }
  }
  .textContainer {
    width: fit-content;
    text-align: center;
    margin-bottom: 50px;
    h2 {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
    p {
      font-family: "Albert Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }
}

// terek
.placeDetailsSection {
  background-color: #f2f2f7;
  .imageContainer {
    height: 550px;
    position: relative;
    .artImg {
      max-height: 100%;
      min-width: 100%;
      object-fit: fill;
    }
    .titleContainer {
      position: absolute;
      display: flex;
      top: 225px;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      gap: 10px;
      h1 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 42px;
        color: white;
      }
      h2 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: white;
      }
    }
  }
  .detailsWrapper {
    border-radius: 24px 24px 0 0;
    background-color: white;
    position: absolute;
    margin-top: -24px;
    width: 100vw;
    .detailsContainer {
      padding: 1.5rem 0;
      h1 {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 24px;
        margin-bottom: 15px;
        &.placeTitle {
          padding: 0 1.5rem;
        }
      }
      .address {
        font-family: "Albert Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #636366;
        display: flex;
        align-items: center;
        padding: 0 1.5rem;
        img {
          height: 20px;
          margin-right: 10px;
        }
      }
      .description {
        margin-top: 48px;
      }
    }
  }

  // imgFullScreenView fix
  .styles-module_close__2I1sI {
    width: 40px;
    height: 40px;
    right: 20px;
    top: 24px;
    z-index: 4;
    line-height: 30px;
    opacity: 0.5 !important;
  }
}
