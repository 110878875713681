.featuredSection {
  margin: 48px 0;
  padding: 1.5rem;
  h1 {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: #1c1c1e;
    margin-bottom: 24px;
  }

  .featuredContainer {
    display: flex;
    gap: 36px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 15px 0;
    .featuredCard {
      max-width: 780px;
      height: 588px;
      position: relative;
      border-radius: 12px;
      cursor: pointer;
      .artImage {
        border-radius: 12px;
        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
        width: 780px;
        height: -webkit-fill-available;
      }
      .textContainer {
        position: absolute;
        bottom: 16px;
        left: 0;
        margin: 0 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        z-index: 1;
        .avatar {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          object-fit: cover;
        }
        h2 {
          color: white;
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
        h3 {
          font-family: "Albert Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: white;
        }
      }
    }
  }
}
