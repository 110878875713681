.mapBtnContainer {
  width: 100%;
  margin-top: 20px;
  position: fixed;
  height: 110px;
  bottom: 25px;
  .mapBtn {
    background-color: transparent;
    cursor: pointer;
    z-index: 2;
    border: none;
    outline: none;
    position: absolute;
    right: 20px;
    bottom: 60px;
    img {
      width: 40px;
      height: 40px;
    }
  }
}
